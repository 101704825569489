<template>
  <div class="banner-registration">
    <div class="container">
      <div class="wireframe"></div>
      <div class="cont">
        <span class="text">现在订购，马上开启自己的电商业务</span>
        <button class="btn"
                @click="onClick">立即订购</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerRegistration',
  methods: {
    onClick () {
      window.open('https://fuwu.taobao.com/ser/detail.htm?spm=a1z13.fuwu_search_result_2023.0.0.42735acaj4WUOF&service_code=ts-1815486&tracelog=search&from_key=%E4%BC%98%E5%8D%96')
    }
  }
}

</script>

<style lang="scss" scoped>
.banner-registration {
  position: relative;
  padding: 20px;
  background-color: rgba(0, 0, 0, 1);

  .wireframe {
    width: 50%;
    height: 50px;
    margin: 0 auto;
    border: 1px solid #364c63;
  }

  .cont {
    position: absolute;
    display: flex;
    width: 50%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .text {
      font-size: 20px;
      color: #fff;
    }

    .btn {
      width: 120px;
      padding: 6px;
      background: linear-gradient(45deg, red, blue);
      color: #fff;
      border: none;
      border-radius: 20px;
    }
  }
}
</style>
