.<template>
  <div>
    <Header />
    <section>
      <div class="container container-space-y-m">
        <device-browser shadow="none">
          <div class="d-flex m-5"
               style="height: 300px;">
            <SmsCodePreview class="flex-shrink-0 mr-5"
                            type="1" />
            <div>
              <div class="h4">活动通知</div>
              用户触发一定的设定后，商家或平台后台系统自动触发的服务通知，比如服务开通、审核结果反馈、扣费状态、商品到达提醒等。
            </div>
          </div>
        </device-browser>
      </div>
    </section>

    <section>
      <div class="container container-space-y-m">
        <device-browser shadow="none">
          <div class="d-flex m-5"
               style="height: 300px;">
            <SmsCodePreview class="flex-shrink-0 mr-5"
                            type="2" />
            <div>
              <div class="h4">出票通知</div>
              用户触发一定的设定后，商家或平台后台系统自动触发的服务通知，比如服务开通、审核结果反馈、扣费状态、商品到达提醒等。
            </div>
          </div>
        </device-browser>
      </div>
    </section>

    <section>
      <div class="container container-space-y-m">
        <device-browser shadow="none">
          <div class="d-flex m-5"
               style="height: 300px;">
            <SmsCodePreview class="flex-shrink-0 mr-5"
                            type="3" />
            <div>
              <div class="h4">活动通知</div>
              用户触发一定的设定后，商家或平台后台系统自动触发的服务通知，比如服务开通、审核结果反馈、扣费状态、商品到达提醒等。
            </div>
          </div>
        </device-browser>
      </div>
    </section>

    <section>
      <div class="container container-space-y-m">
        <device-browser shadow="none">
          <div class="d-flex m-5"
               style="height: 300px;">
            <SmsCodePreview class="flex-shrink-0 mr-5"
                            type="4" />
            <div>
              <div class="h4">付款确认</div>
              用户触发一定的设定后，商家或平台后台系统自动触发的服务通知，比如服务开通、审核结果反馈、扣费状态、商品到达提醒等。
            </div>
          </div>
        </device-browser>
      </div>
    </section>

    <section>
      <div class="container container-space-y-m">
        <device-browser shadow="none">
          <div class="d-flex m-5"
               style="height: 300px;">
            <SmsCodePreview class="flex-shrink-0 mr-5"
                            type="4" />
            <div>
              <div>快递通知</div>
              用户触发一定的设定后，商家或平台后台系统自动触发的服务通知，比如服务开通、审核结果反馈、扣费状态、商品到达提醒等。
            </div>
          </div>
        </device-browser>
      </div>
    </section>

    <BannerRegistration />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import BannerRegistration from '@/components/banner-registration'
import SmsCodePreview from '@/components/sms-code-preview'
import DeviceBrowser from '@/components/device-browser'
export default {
  name: 'SmsCode',
  components: {
    Header,
    Footer,
    BannerRegistration,
    SmsCodePreview,
    DeviceBrowser
  },
  data () {
    return {
      point: {},
      showInfoWindow: true // 显示信息窗口
    }
  },

  methods: {
    mapReady ({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(113.44308, 23.194689)
      map.centerAndZoom(this.point, 17)
    }

  }
}
</script>

<style lang="scss" scoped>
/deep/.device-browser {
  width: 800px;
}
</style>
