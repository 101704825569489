<template>
  <div class="phone-box">
    <img :src="require(`@/assets/images/sms-code/smsimg${type}.png`)">
  </div>
</template>

<script>
export default {
  name: 'SmsCodePreview',
  props: {
    type: {
      type: String,
      default: '1'
    }
  }
}

</script>

<style lang="scss" scoped>
.phone-box {
  position: relative;
  width: 264px;
  height: 561px;
  background: url("~@/assets/images/phone.png") no-repeat;

  img {
    position: absolute;
    top: 18%;
    right: 0;
    left: 0;
    display: block;
    margin: 0 auto;
    transition: all 0.3s;
  }
}
</style>
